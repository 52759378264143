import { Box,   Button,   Center, Flex,   Image,  Stack, Text } from "@chakra-ui/react";
import { cfg } from "../../dados/dados";
import GradientBorder from "../../components/VisionUI/GradientBorder";  
import { NavLink } from "react-router-dom";
import { FaKey, FaSignInAlt, FaUser } from "react-icons/fa";
import { hexToRGB } from "../../Function";
import { Bt1 } from "../../components/Button/ButtonView";
import InputCampo from "../../components/Input/modelos/InputCampo"; 
import { useEffect, useState } from "react";
import { setSession, sendForm } from "../../conn/WebPainel";
import BoxWindow from "../../components/BoxWindow/BoxWindow";

const LoginPainel=(props)=>{   
    const [alerta,setAlerta] = useState({});  
    useEffect(()=>{

    },[alerta]) 
    return (
        <Flex position='relative'>
            <Flex
                minH='100vh'
                h={{ base: "100vh", lg: "100vh" }}
                w='full' 
                align={'center'} 
                justify={'center'}  
                flexDirection='column'  >
                    <Center>
                        <GradientBorder  
                            borderRadius={'20px'}
                            boxShadow={'lg'}
                            bg='radial-gradient(69.43% 69.43% at 50% 50%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 100%),
                                radial-gradient(60% 51.57% at 50% 50%, rgba(0, 0, 0, 0.4) 0%, rgba(88, 44, 255, 0.05) 100%),
                                radial-gradient(54.8% 53% at 50% 50%, rgba(0, 0, 0, 0.4) 0%, rgba(21, 21, 21, 0) 100%)'
                            p='2px' me={{ base: "none", lg: "0px", xl: "none" }}>
                            <Box pos={'relative'} bg={'rgba('+hexToRGB(cfg.theme.cores.bg002)+',0.2)'} borderRadius={'20px'} as={'form'}  onSubmit={(e)=>{
                                e.preventDefault();   
                                setAlerta({load:true}) 
                                let json = {};  
                                new FormData(e.target).forEach(function(value, prop){ 
                                    json[prop] = value;
                                });
                                sendForm({ modulo:'usuario',cmd:'login',form:json,callback:(result)=>{ 
                                    if(result.erro){
                                        result.fechar =true;
                                        result.size = 'md';  
                                        setAlerta(result);
                                    }else{  
                                        setAlerta({close:true});  
                                    }
                                    
                                }}); 
                            }} > 
                                <Flex   zIndex={'2'}   h={'100%'}  style={{ userSelect: "none" }}  mx={{ base: "auto", lg: "unset" }}  w={{ base: "340px", sm: "400px", lg: "450px" }}  p={{ base: "20px", sm: "40px", lg: "50px" }}>
                                     <Flex direction='column' w='100%' >
                                        <Center> 
                                            <Image w={{ lg: '200px', xl: '270px', '2xl': '340px' }} objectFit='fill' src={"/assets/"+cfg.site+"/logo_menu.webp"} alt={'Logo '+cfg.nome} />
                                        </Center>   
                                        <Stack py={'10px'} gap={'0px'}>
                                            
                                            <InputCampo
                                                icone={FaUser}
                                                tipo='user'
                                                titulo='E-mail'
                                                name='login'
                                                placeholder="Ingrese el e-mail"
                                                error={''}
                                            />
                                            <InputCampo
                                                icone={FaKey}
                                                tipo='password'
                                                titulo='Contraseña'
                                                name='senha'
                                                error={''}
                                                placeholder="Ingrese el Contraseña"
                                            />
                                        </Stack> 
                                        <Flex
                                            flexDirection='column'
                                            justifyContent='center'
                                            alignItems='center'
                                            maxW='100%'
                                            mb='20px'>
                                            <Text  fontWeight='medium'> 
                                                <NavLink to={'/painel/lost'} >
                                                    <Text as='span' color={'tema.cor001'} ms='5px' fontWeight='bold'>
                                                    ¡Olvidé mi contraseña!
                                                    </Text>
                                                </NavLink>
                                            </Text>
                                        </Flex>
                                        <Flex
                                            flexDirection='column'
                                            justifyContent='center'
                                            alignItems='center'
                                            maxW='100%'
                                            mb='20px'>
                                                
                                                <Bt1 bg1={ '#000000'} bg2={ '#222222'} leftIcon={<FaSignInAlt />} >Acceso</Bt1>
                                        </Flex> 
                                    </Flex>   
                                </Flex>   
                            </Box> 
                        </GradientBorder>
                    
                    </Center>    
                    <Box pos={'relative'}  p='10px 0'     >
                        <Text as='span' color={'tema.cor001'} ms='5px'  fontSize={{base:'10px',sm:'12px'}} >
                            © 2018 - {new Date().getFullYear()} {cfg.nome}. Todos os Direitos Reservados.
                        </Text>
                    </Box> 
            </Flex> 
            <BoxWindow param ={alerta} />
        </Flex>
    );
}
export default  LoginPainel;